import TabList from 'components/TabList';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import ContactUsForm from '@components/ContactUsForm';
import DemoModal from '@components/Layout/DemoModal';
import { SectionContainer } from '@components/Layout/Layout.styles';
import Modal from '@components/Modal';

import { SolutionsValues } from '@helpers/enum/Solutions';
import { hasWindow, trackEventAnalytics } from '@helpers/window';
import { SIZES } from '@style/sizes';

import solution_icon_1 from '../../pages/solutions/assets/solution_icons_1.svg';
import solution_icon_2 from '../../pages/solutions/assets/solution_icons_2.svg';
import solution_icon_3 from '../../pages/solutions/assets/solution_icons_3.svg';
import solution_icon_4 from '../../pages/solutions/assets/solution_icons_4.svg';
import solution_icon_5 from '../../pages/solutions/assets/solution_icons_5.svg';
import solution_icon_6 from '../../pages/solutions/assets/solution_icons_6.svg';
import solution_icon_7 from '../../pages/solutions/assets/solution_icons_7.svg';
import {
  BulletContainer,
  Text,
  LastText,
  Subtitle,
  Title,
  Wrapper,
  WrapperBullet,
  WrapperLastCopy,
  WrapperSecondModule,
  WrapperText,
  WrapperTitle,
  WrapperTriangleLeft,
  WrapperTriangleRight,
  WrapperContent,
  Button,
  WrapperButton,
  Bullet,
  BulletWrapper,
  BulletColumn,
  WrapperLastImage,
  WrapperFirstModule,
  WrapperContentTitle,
  WrapperPreTitle,
  PreTitle,
  WrapperTitlePage,
  TitlePage,
  TextOne,
  WrapperThirdModule,
  WrapperIcons,
  WrapperTextValue,
  WrapperValue,
  WrapperSecondTriangleRight,
  ContentLastImage,
  WrapperSecondTriangleLeft,
  ContentTriangleLeft,
  ContentTriangleRight,
  ContentFirstImage,
  Icons,
  ContentValue,
  WrapperColumn,
  WrapperMenuMobile,
  MenuOptionMobile,
  ThirdModuleWrapper,
  WrapperFirstImage,
} from './styles';
import { ISolutionProps } from './types';

const Solution = (props: ISolutionProps) => {
  const {
    imageSectionOne,
    imageSectionTwo,
    mobileImageSectionOne,
    mobileImageSectionTwo,
    redirectSignUp,
    handleOpenModalContactUs,
    loading,
    onSubmitContactUs,
    openModalContactUs,
    handleOpenModalDemo,
    onSubmitDemo,
    openModalDemo,
  } = props;
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<SolutionsValues>(
    SolutionsValues.REALSCORE_PLATFORM,
  );
  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.TABLET_740}px)`,
  });
  const menuOptions = [
    { text: t('SOLUTION:MENU_VALUE_ONE'), link: '#command-center' },
    { text: t('SOLUTION:MENU_VALUE_TWO'), link: '#sales-assistant' },
    { text: t('SOLUTION:MENU_VALUE_THREE'), link: '#profesional-services' },
  ];

  const bulletTextSectionOne = [
    {
      column: [
        t('SOLUTION:FIRST_MODULE_BULLET_ONE'),
        t('SOLUTION:FIRST_MODULE_BULLET_TWO'),
        t('SOLUTION:FIRST_MODULE_BULLET_THREE'),
        t('SOLUTION:FIRST_MODULE_BULLET_FOUR'),
      ],
    },
    {
      column: [
        t('SOLUTION:FIRST_MODULE_BULLET_FIVE'),
        t('SOLUTION:FIRST_MODULE_BULLET_SIX'),
        t('SOLUTION:FIRST_MODULE_BULLET_SEVEN'),
      ],
    },
  ];

  const bulletTextSectionTwo = [
    {
      column: [
        t('SOLUTION:SECOND_MODULE_BULLET_ONE'),
        t('SOLUTION:SECOND_MODULE_BULLET_TWO'),
        t('SOLUTION:SECOND_MODULE_BULLET_THREE'),
      ],
    },
    { column: [t('SOLUTION:SECOND_MODULE_BULLET_FOUR'), t('SOLUTION:SECOND_MODULE_BULLET_FIVE')] },
  ];

  const thirdModuleValues = [
    {
      row: [
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_ONE'),
          image: solution_icon_1,
          alt: 'strategic-icon',
        },
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_TWO'),
          image: solution_icon_2,
          alt: 'reporting-icon',
        },
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_THIRD'),
          image: solution_icon_3,
          alt: 'detailing-icon',
        },
      ],
    },
    {
      row: [
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_FOUR'),
          image: solution_icon_4,
          alt: 'target-icon',
        },
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_FIVE'),
          image: solution_icon_5,
          alt: 'folder-icon',
        },
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_SIX'),
          image: solution_icon_6,
          alt: 'comparative-icon',
        },
        {
          text: t('SOLUTION:THIRD_MODULE_TEXT_IMAGE_SEVEN'),
          image: solution_icon_7,
          alt: 'thematic-icon',
        },
      ],
    },
  ];

  const handleTabChange = (value: number | string) => {
    setSelectedTab(value as SolutionsValues);
    const tabSelected = menuOptions.find((element) => element.text === value);
    trackEventAnalytics('Click Solution Menu', { tabSelected: tabSelected!.text });
    if (hasWindow()) {
      window.location.href = tabSelected!.link;
    }
  };

  return (
    <Wrapper>
      <Modal open={openModalContactUs} width={500} onClose={handleOpenModalContactUs}>
        <ContactUsForm loading={loading} onSubmit={onSubmitContactUs} />
      </Modal>
      <Modal open={openModalDemo} width={500} onClose={handleOpenModalDemo}>
        <DemoModal loading={loading} onSubmit={onSubmitDemo} />
      </Modal>
      <SectionContainer>
        <WrapperContentTitle>
          <WrapperPreTitle>
            <PreTitle>{t('SOLUTION:PRE_TITLE')}</PreTitle>
          </WrapperPreTitle>
          <WrapperTitlePage>
            <TitlePage>{t('SOLUTION:TITLE')}</TitlePage>
          </WrapperTitlePage>
          <WrapperText>
            <TextOne>{t('SOLUTION:TEXT_ONE')}</TextOne>
          </WrapperText>
          {!isMobile && (
            <TabList
              items={menuOptions}
              handleTabChange={handleTabChange}
              selectedTab={selectedTab}
            />
          )}
          {isMobile &&
            menuOptions.map((element) => (
              <WrapperMenuMobile>
                <MenuOptionMobile href={element.link}>{element.text}</MenuOptionMobile>
              </WrapperMenuMobile>
            ))}
        </WrapperContentTitle>
        <WrapperFirstModule>
          <WrapperTitle>
            <Title color="primary">
              {isMobile
                ? t('SOLUTION:FIRST_MODULE_TITLE_MOBILE')
                : t('SOLUTION:FIRST_MODULE_TITLE')}
            </Title>
            <Subtitle color="primary">{t('SOLUTION:FIRST_MODULE_SUBTITLE')}</Subtitle>
          </WrapperTitle>
          <WrapperText>
            <Text color="grey">{t('SOLUTION:FIRST_MODULE_TEXT_ONE')}</Text>
          </WrapperText>
          <WrapperBullet>
            {bulletTextSectionOne.map((element) => {
              return (
                <BulletColumn>
                  {element.column.map((text) => (
                    <BulletContainer>
                      <BulletWrapper>
                        <Bullet />
                      </BulletWrapper>
                      <Text color="grey">{text}</Text>
                    </BulletContainer>
                  ))}
                </BulletColumn>
              );
            })}
          </WrapperBullet>
          <WrapperLastCopy>
            <LastText color="grey">{t('SOLUTION:FIRST_MODULE_LAST_TEXT')}</LastText>
            <WrapperButton>
              <Button onClick={handleOpenModalDemo}>
                {t('SOLUTION:FIRST_MODULE_BUTTON_COPY')}
              </Button>
            </WrapperButton>
          </WrapperLastCopy>
        </WrapperFirstModule>
        <ContentFirstImage>
          <WrapperFirstImage>
            {isMobile && (
              <GatsbyImage alt={'sales-assistant-module'} image={mobileImageSectionOne} />
            )}
            {!isMobile && <GatsbyImage alt={'sales-assistant-module'} image={imageSectionOne} />}
          </WrapperFirstImage>
        </ContentFirstImage>
      </SectionContainer>
      {!isMobile && (
        <ContentTriangleLeft id="sales-assistant">
          <WrapperTriangleLeft>
            <WrapperSecondTriangleLeft />
          </WrapperTriangleLeft>
        </ContentTriangleLeft>
      )}
      <WrapperContent>
        <SectionContainer>
          <WrapperSecondModule>
            <WrapperTitle>
              <Title>
                {isMobile
                  ? t('SOLUTION:SECOND_MODULE_TITLE_MOBILE')
                  : t('SOLUTION:SECOND_MODULE_TITLE')}
              </Title>
              <Subtitle>{t('SOLUTION:SECOND_MODULE_SUBTITLE')}</Subtitle>
            </WrapperTitle>
            <WrapperText>
              <Text>{t('SOLUTION:SECOND_MODULE_TEXT_ONE')}</Text>
            </WrapperText>
            <WrapperBullet>
              {bulletTextSectionTwo.map((columns) => {
                return (
                  <BulletColumn>
                    {columns.column.map((text) => (
                      <BulletContainer>
                        <BulletWrapper>
                          <Bullet />
                        </BulletWrapper>
                        <Text>{text}</Text>
                      </BulletContainer>
                    ))}
                  </BulletColumn>
                );
              })}
            </WrapperBullet>
            <WrapperLastCopy>
              <LastText>{t('SOLUTION:SECOND_MODULE_LAST_TEXT')}</LastText>
              <WrapperButton>
                <Button onClick={redirectSignUp}>{t('SOLUTION:SECOND_MODULE_BUTTON_COPY')}</Button>
              </WrapperButton>
            </WrapperLastCopy>
          </WrapperSecondModule>
        </SectionContainer>
        <ContentLastImage>
          <WrapperLastImage>
            {!isMobile && <GatsbyImage alt={'sales-assistant-module'} image={imageSectionTwo} />}
            {isMobile && (
              <GatsbyImage alt={'sales-assistant-module'} image={mobileImageSectionTwo} />
            )}
          </WrapperLastImage>
        </ContentLastImage>
      </WrapperContent>
      {!isMobile && (
        <ContentTriangleRight id="profesional-services">
          <WrapperTriangleRight>
            <WrapperSecondTriangleRight />
          </WrapperTriangleRight>
        </ContentTriangleRight>
      )}
      <ThirdModuleWrapper>
        <SectionContainer>
          <WrapperThirdModule>
            <WrapperTitle>
              <Title color="primary">
                {isMobile
                  ? t('SOLUTION:THIRD_MODULE_TITLE_MOBILE')
                  : t('SOLUTION:THIRD_MODULE_TITLE')}
              </Title>
              <Subtitle color="primary">{t('SOLUTION:THIRD_MODULE_SUBTITLE')}</Subtitle>
            </WrapperTitle>
            <WrapperText>
              <Text color="grey">{t('SOLUTION:THIRD_MODULE_TEXT_ONE')}</Text>
            </WrapperText>
            <WrapperValue>
              {thirdModuleValues.map((columns) => {
                return (
                  <ContentValue>
                    {columns.row.map((element) => (
                      <WrapperColumn>
                        <WrapperIcons>
                          <Icons src={element.image} alt={element.alt} />
                        </WrapperIcons>
                        <WrapperTextValue>
                          <Text color="grey">{element.text}</Text>
                        </WrapperTextValue>
                      </WrapperColumn>
                    ))}
                  </ContentValue>
                );
              })}
            </WrapperValue>
            <WrapperLastCopy>
              <LastText color="grey">{t('SOLUTION:THIRD_MODULE_LAST_TEXT')}</LastText>
              <WrapperButton>
                <Button onClick={handleOpenModalContactUs}>
                  {t('SOLUTION:THIRD_MODULE_BUTTON_COPY')}
                </Button>
              </WrapperButton>
            </WrapperLastCopy>
          </WrapperThirdModule>
        </SectionContainer>
      </ThirdModuleWrapper>
    </Wrapper>
  );
};

export default Solution;
