import React from 'react';

import { SelectedTab, Tab, TabsList, TabText } from './styles';
import { ITabProps } from './types';

const TabList = (props: ITabProps) => {
  const { items, handleTabChange, selectedTab } = props;

  return (
    <TabsList>
      {items.map((element) => {
        return (
          <Tab
            isSelected={selectedTab === element.text}
            onClick={() => {
              handleTabChange(element.text);
            }}
          >
            <TabText>{element.text}</TabText>
            <SelectedTab isSelected={selectedTab === element.text} />
          </Tab>
        );
      })}
    </TabsList>
  );
};

export default TabList;
