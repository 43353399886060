import styled from 'styled-components';

import { SIZES } from '@style/sizes';

import { ITabsProps } from './types';

export const Tab = styled.div<ITabsProps>`
  width: 100%;
  height: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  text-align: center;
  justify-content: end;
  align-items: center;
  border-radius: 10px;
  color: ${(props) => props.theme.palette.secondary};
  cursor: pointer;
`;

export const TabsList = styled.div`
  width: 90%;
  height: 78px;
  padding: 0 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.common.white};
  justify-content: space-between;
  box-shadow: ${(props) => props.theme.boxShadow.module} !important;
`;

export const SelectedTab = styled.div<ITabsProps>`
  border-bottom: 5px solid
    ${(props) =>
      props.isSelected ? props.theme.palette.common.green : props.theme.palette.common.white};
  width: 100%;
  align-items: baseline;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: center;
`;

export const TabText = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    width: 50%;
    margin-bottom: 8px;
  }
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  margin-bottom: 21px;
`;
