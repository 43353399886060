import styled from 'styled-components';

import { SIZES } from '@style/sizes';

import { ITextProp } from './types';

export const Wrapper = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1024}px) {
    background: linear-gradient(
      0deg,
      ${(props) => props.theme.palette.quinternary} 89.5%,
      ${(props) => props.theme.palette.common.white} 0%
    );
  }
  @media (max-width: ${SIZES.TABLET_740}px) {
    background: ${(props) => props.theme.palette.common.white};
  }
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.palette.quinternary} 90.5%,
    ${(props) => props.theme.palette.common.white} 0%
  );
  width: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-size: 10%;
`;

export const WrapperTriangleLeft = styled.div`
  border-right: 100vw solid ${(props) => props.theme.palette.quinternary};
  border-bottom: 10vw solid transparent;
`;

export const WrapperTriangleRight = styled.div`
  border-right: 100vw solid ${(props) => props.theme.palette.primary};
  border-bottom: 10vw solid transparent;
`;

export const ContentTriangleRight = styled.div`
  @media (max-width: ${SIZES.TABLET_992}px) {
    bottom: 42px;
  }
  position: relative;
  bottom: 20px;
`;
export const ContentTriangleLeft = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1440}px) {
    bottom: 70px;
  }
  @media (max-width: ${SIZES.DESKTOP_1200}px) {
    bottom: 40px;
  }
  position: relative;
  bottom: 80px;
`;

export const WrapperSecondTriangleLeft = styled.div`
  border-right: 180vw solid transparent;
  border-bottom: 16vw solid ${(props) => props.theme.palette.primary};
  position: absolute;
  top: 0;
  z-index: 1;
`;
export const WrapperSecondTriangleRight = styled.div`
  border-right: 180vw solid transparent;
  border-bottom: 16vw solid ${(props) => props.theme.palette.common.white};
  position: absolute;
  top: 0;
  z-index: 1;
`;
export const WrapperSecondModule = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    padding: 50px 20px 0;
  }
  padding: 0 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperFirstModule = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    padding: 0 20px;
  }
  padding: 0 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperThirdModule = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    padding: 50px 20px;
  }
  padding: 0px 100px;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary};
`;

export const WrapperTitle = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    flex-direction: column;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const WrapperText = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const WrapperBullet = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    flex-direction: column;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const WrapperLastCopy = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperButton = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2<ITextProp>`
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 24px;
  line-height: 28px;
  color: ${(props) =>
    props.color === 'primary' ? props.theme.palette.primary : props.theme.palette.common.white};
`;

export const Subtitle = styled.p<ITextProp>`
  @media (max-width: ${SIZES.TABLET_740}px) {
    padding: 0;
  }
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.regular};
  font-size: 24px;
  line-height: 28px;
  color: ${(props) =>
    props.color === 'primary' ? props.theme.palette.primary : props.theme.palette.common.white};
  padding-left: 5px;
`;

export const Text = styled.p<ITextProp>`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 18px;
  line-height: 28px;
  color: ${(props) =>
    props.color === 'grey' ? props.theme.palette.septernary : props.theme.palette.common.white};
`;

export const BulletContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0;
`;

export const BulletColumn = styled.div`
  width: 100%;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const BulletWrapper = styled.div`
  padding: 10px 10px 10px 0;
`;

export const Bullet = styled.div`
  background-color: ${(props) => props.theme.palette.common.green};
  height: 8px;
  width: 8px;
  border-radius: 50px;
`;

export const LastText = styled.p<ITextProp>`
  font-family: ${(props) => props.theme.fontFamilies.europa.regular};
  font-size: 20px;
  line-height: 28px;
  max-width: 850px;
  text-align: center;
  color: ${(props) =>
    props.color === 'grey' ? props.theme.palette.septernary : props.theme.palette.common.white};
`;

export const Button = styled.button`
  width: 236px;
  height: 50px;
  background-color: ${(props) => props.theme.palette.common.green};
  color: ${(props) => props.theme.palette.primary};
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  font-size: 14px;
  line-height: 14px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: ${(props) => props.theme.palette.sexternary};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const WrapperFirstImage = styled.div`
  width: 1000px;
  position: relative;
  z-index: 1;
`;

export const WrapperLastImage = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    background-color: ${(props) => props.theme.palette.primary};
  }
  width: 1000px;
  position: relative;
  z-index: 1;
`;

export const WrapperContentTitle = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1200}px) {
    padding: 0 20px 40px 20px;
  }
  display: flex;
  padding: 0 20px 40px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperPreTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PreTitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 24px;
  line-height: 56px;
  color: ${(props) => props.theme.palette.primary};
`;

export const WrapperTitlePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TitlePage = styled.h1`
  @media (max-width: ${SIZES.TABLET_740}px) {
    font-size: 36px;
    line-height: 46px;
    text-align: center;
  }
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.bold};
  font-size: 48px;
  text-align: center;
  line-height: 56px;
  color: ${(props) => props.theme.palette.primary};
`;

export const TextOne = styled.p`
  margin: 20px 0;
  font-family: ${(props) => props.theme.fontFamilies.ubuntu.regular};
  font-size: 20px;
  text-align: center;
  line-height: 28px;
  color: ${(props) => props.theme.palette.septernary};
`;

export const WrapperMenuMobile = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 0 30px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eff8f8;
`;

export const MenuOptionMobile = styled.a`
  width: 100%;
  height: 60px;
  border-radius: 10px;
  font-family: ${(props) => props.theme.fontFamilies.europa.bold};
  font-size: 24px;
  background-color: ${(props) => props.theme.palette.quinternary};
  line-height: 28px;
  color: ${(props) => props.theme.palette.secondary};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const WrapperValue = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const WrapperIcons = styled.div`
  width: 82px;
  width: 82px;
  margin-bottom: 10px;
`;

export const Icons = styled.img`
  width: 100%;
`;

export const WrapperTextValue = styled.div`
  width: 100%;
  text-align: center;
`;

export const ContentLastImage = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1366}px) {
    max-height: 300px;
  }
  @media (max-width: ${SIZES.TABLET_740}px) {
    overflow: hidden;
  }
  width: 100%;
  max-height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ContentFirstImage = styled.div`
  @media (max-width: ${SIZES.DESKTOP_1200}px) {
    max-height: 380px;
  }
  @media (max-width: ${SIZES.TABLET_992}px) {
    max-height: 310px;
  }
  @media (max-width: ${SIZES.TABLET_740}px) {
    max-height: 500px;
    width: 700px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    width: 430px;
  }
  width: 100%;
  max-height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const ContentValue = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    flex-direction: column;
    margin: 0;
  }
  justify-content: center;
  width: 100%;
  margin: 15px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const WrapperColumn = styled.div`
  @media (max-width: ${SIZES.TABLET_740}px) {
    max-width: 90%;
    margin: 30px 0;
  }
  max-width: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ThirdModuleWrapper = styled.div`
  background-color: white;
  width: 100%;
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
