import { graphql } from 'gatsby';
import useIsClient from 'hooks/useIsClient';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContactUsDetails } from '@components/ContactUsForm/types.ContactUsForm';
import Layout from '@components/Layout';
import { UserDetails } from '@components/Layout/DemoModal/demoModal.types';
import UserMessage from '@components/Layout/UserMessage';
import SEO from '@components/SEO';
import Solution from '@components/Solution';

import { SIGN_UP_APP_URL } from '@config/config';
import { PATHS } from '@global/urls';
import { post } from '@helpers/api';
import { UserMessageType } from '@helpers/enum/UserMessageTypes';
import { getImageData, ParseImages } from '@helpers/imageHelper';
import { hasWindow, trackEventAnalytics } from '@helpers/window';

function SolutionPage({ data }: any) {
  const images = ParseImages(data);
  const { t } = useTranslation();

  const [openModalContactUs, setOpenModalContactUs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [userMessageType, setUserMessageType] = useState(UserMessageType.SUCCESS);
  const [openModalDemo, setOpenModalDemo] = useState(false);

  const redirectSignUp = () => {
    trackEventAnalytics('Click Get In Touch Button Solution Page', {
      route: PATHS.SOLUTION,
    });
    if (hasWindow()) {
      window.location.href = SIGN_UP_APP_URL!;
    }
  };

  const onSubmitContactUs = async (userDetails: ContactUsDetails) => {
    try {
      setLoading(true);
      await post('users/contact-us', { ...userDetails, isMarketingEmail: true });
      setLoading(false);
      setOpenModalContactUs(false);
      setUserMessageType(UserMessageType.SUCCESS);
      setUserMessage('The email was sent correctly');
    } catch (error) {
      setLoading(false);
      setOpenModalContactUs(false);
      setUserMessageType(UserMessageType.FAIL);
      setUserMessage('There was a problem sending your email, please try again');
    }
  };

  const onSubmitDemo = async (userDetails: UserDetails) => {
    try {
      setLoading(true);
      await post('users/demoEmail', userDetails);
      setLoading(false);
      setOpenModalDemo(false);
      setUserMessageType(UserMessageType.SUCCESS);
      setUserMessage('The email was sent correctly');
    } catch (error) {
      setLoading(false);
      setOpenModalDemo(false);
      setUserMessageType(UserMessageType.FAIL);
      setUserMessage('There was a problem sending your email, please try again');
    }
  };
  const { key } = useIsClient();

  useEffect(() => {
    if (userMessage !== '') {
      if (hasWindow()) {
        window.scrollTo(0, 0);
      }
      setTimeout(() => {
        setUserMessage('');
      }, 3000);
    }
  }, [userMessage]);

  return (
    <Layout footerLogo={getImageData(images, 'footer')} currentRoute={PATHS.SOLUTION}>
      <SEO metaDescription={t('SEO:METADESCRIPTION')} metaTitle={t('SEO:METATITLE')} />
      {userMessage !== '' && <UserMessage message={userMessage} messageType={userMessageType} />}
      <Solution
        key={key}
        imageSectionOne={getImageData(images, 'solution-img1')}
        imageSectionTwo={getImageData(images, 'solution-img2')}
        mobileImageSectionOne={getImageData(images, 'solution-mobile-1')}
        mobileImageSectionTwo={getImageData(images, 'solution-mobile-2')}
        redirectSignUp={redirectSignUp}
        loading={loading}
        onSubmitContactUs={onSubmitContactUs}
        handleOpenModalContactUs={() => {
          trackEventAnalytics(
            !openModalContactUs
              ? 'Open Contact US Modal - Solution'
              : 'Close Contact US Modal - Solution',
            {
              route: PATHS.SOLUTION,
            },
          );
          setOpenModalContactUs(!openModalContactUs);
        }}
        openModalContactUs={openModalContactUs}
        openModalDemo={openModalDemo}
        onSubmitDemo={onSubmitDemo}
        handleOpenModalDemo={() => {
          trackEventAnalytics(
            !openModalDemo ? 'Open Demo Modal - Solution' : 'Close Demo Modal - Solution',
            {
              route: PATHS.SOLUTION,
            },
          );

          setOpenModalDemo(!openModalDemo);
        }}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($language: String!) {
    allFile(
      filter: {
        relativeDirectory: { in: ["components/Layout/Footer/assets", "pages/solutions/assets"] }
      }
    ) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    locales: allLocale(
      filter: {
        ns: { in: ["topbar", "footer", "demoModal", "solution", "contactUs", "SEO"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default SolutionPage;
